@import '../../../../styles/fonts';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.PopupSchool {
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba($blueDark, 0.5);
	backdrop-filter: blur(1px);
	width: 100%;
	height: 100%;
	@include flex('center', 'center');

	.PopupSchool-content {
		position: relative;
		width: 60em;
		background-color: $white;
		border-radius: 0.5em;

		.PopupSchool-header {
			padding: 1em 2em;
			background-color: $blue;
			border-top-left-radius: 0.5em;
			border-top-right-radius: 0.5em;
			span {
				color: white;
				font-weight: bold;
				font-size: 1.25em;
			}
		}

		.PopupSchool-body {
			padding: 1em 2em;
			.PopupSchool-title {
				padding-bottom: 0.2em;
				span {
					font-size: 1.25em;	
				}
			}
			.PopupSchool-subtitle {
				margin-top: 0.2em;
				span {
					font-size: 0.8em;
				}
			}
			.PopupSchool-infoWrap {
				padding-bottom: 1em;
				margin-bottom: 1em;
				border-bottom: 1px solid $blue;
				@include flex('', '', 'row');
				.PopupSchool-infoColumn {
					position: relative;

					&.right {
						border-left: 1px solid $blue;
						padding-left: 3em;
					}

					.PopupSchool-info {
						@include flex('flex-start', 'center');
						.PopupSchool-infoLabel {
							@include flex('flex-start', 'center');
							width: 10em;
							height: 1.25em;
							margin-bottom: 0.2em;
						}
						.PopupSchool-infoData {
							@include flex('flex-start', 'center');
							width: 21em;
							min-height: 1.25em;
							margin-bottom: 0.2em;
						}

						.PopupSchool-input {
							width: 15em;
							height: 1.25em;
							margin-bottom: 0.2em;
							input {
								font-size: 1em;
								font-weight: 400;
								width: 100%;
								height: 100%;
								padding-left: 0.5em;
								border: 1px solid black;
								border-radius: 0.25em;
							}
						}
					}

					.PopupSchool-infoButtons {
						@include flex('space-between');
						margin-top: 1em;
						width: 25em;
					}

					.PopupSchool-infoButton {
						margin-top: 1em;
						width: 15em;
					}

					.PopupSchool-pdfDownloadLink {
						position: absolute;
						bottom: 0;
						width: 20em;
						a {
							text-decoration: none;
						}
					}
				}
			}

			.PopupSchool-facilitatorsAndTags {
				@include flex('space-between','flex-start');
				margin-bottom: 1.5em;
				.PopupSchool-facilitatorsWrap {
					width: 28em;
					.PopupSchool-facilitators {
						position: relative;
						width: 100%;
						height: 15em;
						max-height: 15em;
						overflow: auto;
						.PopupSchool-facilitator {
							height: 2em;
							@include flex('space-between', 'center');
							&:nth-child(even) {
								background-color: rgba($blue, 0.2);
							}
							.PopupSchool-facilitatorEmail {
								padding: 0 0.5em;
								white-space: nowrap;
								span {
									font-weight: normal;
								}
							}
							.PopupSchool-removeFacilitator {
								width: 2em;
								.PopupSchool-removeFacilitatorBtn {
									width: 1.25em;
									height: 1.25em;
									background-image: url('../../../../assets/images/icons/icon-close.svg');
									cursor: pointer;
								}
							}
						}
					}
		
					.PopupSchool-addFacilitator {
						width: 100%;
						.PopupSchool-addFacilitatorWrap {
							width: 100%;
							@include flex('space-between', 'flex-start');
							.PopupSchool-addFacilitatorInput {
								width: 15em;
								height: 2em;
								input {
									font-size: 1em;
									font-weight: 400;
									width: 100%;
									height: 100%;
									padding-left: 0.5em;
									border: 1px solid black;
									border-radius: 0.25em;
								}
							}
						}
						.PopupSchool-addFacilitatorErr {
							height: 1em;
							line-height: 1;
							margin: 0.25em 0 0.75em 0;
							span {
								font-size: 0.8em;
								color: red;
							}
						}
						.PopupSchool-addFacilitatorButton {
							width: 10em;
							height: 2em;
						}
					}
				}

				.PopupSchool-tagsWrap {
					width: 25em;
					border-left: 1px solid $blue;
					padding-left: 3em;
					.PopupSchool-tags {
						position: relative;
						width: 100%;
						height: 15em;
						max-height: 15em;
						overflow: auto;
						.PopupSchool-tag {
							height: 2em;
							@include flex('space-between', 'center');
							&:nth-child(even) {
								background-color: rgba($blue, 0.2);
							}
							.PopupSchool-tagValue {
								padding: 0 0.5em;
								white-space: nowrap;
								span {
									font-weight: normal;
								}
							}
							.PopupSchool-removeTag {
								width: 2em;
								.PopupSchool-removeTagBtn {
									width: 1.25em;
									height: 1.25em;
									background-image: url('../../../../assets/images/icons/icon-close.svg');
									cursor: pointer;
								}
							}
						}
					}
				}
			}

			
			

			.PopupSchool-buttons {
				text-align: center;
				.PopupSchool-button {
					display: inline-block;
					width: 10em;
				}
			}
		}		
	}
}