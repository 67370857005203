@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';

.FacilitatorFilters {
	position: relative;
	background-color: $white;
	padding: 1em;
	border-radius: 0.5em;
	.FacilitatorFilters-filter {
		@include flex('space-between', 'center');
		.FacilitatorFilters-filterTitle {
			@include no-select();
			width: 5em;
		}
		.FacilitatorFilters-filterOptions {
			@include flex('flex-start', 'center');
			width: calc(100% - 7em);
			.FacilitatorFilters-filterOption {
				@include no-select();
				position: relative;
				width: 8em;
				padding-left: 1.5em;
				cursor: pointer;
				span {
					font-weight: normal;
				}
				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: calc(50% - 0.65em);
					width: 1em;
					height: 1em;
					border: 0.15em solid $blue;
				}
				&.selected::before {
					background-color: $blue;
				}
			}
		}
	}
}		