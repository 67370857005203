@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.AdminTasks {
	position: relative;
	width: 70em;
	padding-top: 2em;

	.AdminTasks-nav {
		position: relative;
		width: 100%;
		overflow: hidden;
		@include flex('space-between');
		.AdminTasks-navItem {
			width: 16em;
			height: 2.5em;
			background-color: $white;
			border-top-left-radius: 0.5em;
			border-top-right-radius: 0.5em;
			cursor: pointer;
			@include flex('center', 'center');
			@include opacity(0.5);
			span {
				font-size: 1.25em;
			}
			&:hover {
				@include opacity(0.75);
			}
			&.selected {
				@include opacity(1);
			}
		}
	}

	.AdminTasks-table {
		background-color: $white;
		padding: 1em;
		border-bottom-left-radius: 0.5em;
		border-bottom-right-radius: 0.5em;
		.AdminTasks-header {
			@include flex('space-between');
			border-bottom-color: $blueDark;
			border-bottom-style: solid;
			border-width: 0.1em;
			padding-bottom: 0.5em;
			margin-bottom: 0.5em;
			padding-right: 1em;
			.AdminTasks-title {
				position: relative;
				text-align: right;
				width: calc(48em / 8);
				&.text {
					text-align: left;
					width: 20em;
				}				
				span {
					display: inline-block;
					color: $blueDark;
					font-size: 1.125em;
					font-weight: bold;
					@include no-select();
				}
			}
		}
	
		.AdminTasks-body {
			height: 37em;
			padding-right: 1em;
			padding-bottom: 10em;
			overflow: auto;
			@include hide-scrollbar();
			.AdminTasks-row {
				position: relative;
				margin-bottom: 0.5em;
				&:nth-child(odd) {
					background-color: rgba($blue, 0.2);
				}
				@include flex('space-between');			
				.AdminTasks-value {
					position:relative;
					text-align: right;
					width: calc(48em / 8);
					height: 1.4em;
					
					&.text {
						width: 20em;
						text-align: left;
						span:nth-child(2) {
							right: auto;
							width: 20em;
						}
					}
					span {
						position: relative;
						display: inline-block;
						width: 100%;
						height: 100%;
						padding-top: 0.35em;
						color: $blueDark;
						font-weight: 400;
						line-height: 1;
						
						&:nth-child(1) {
							position: relative;
							overflow: hidden;
							white-space: nowrap;
						}
						&:nth-child(2) {
							display: none;
							position: absolute;
							height: auto;
							right: 2em;
							top: 1.5em;
							padding: 1em;
							font-size: 1em;
							background-color: white;
							border: 1px solid $blueDark;
							border-radius: 0.5em;
							text-align: left;
							width: 10em;
							img {
								width: 15em;
							}
						}
					}
					
					
				}
			}
		}	
		&.multiple-choice {
			.AdminTasks-body {
				.AdminTasks-row {
					.AdminTasks-value {
						&.hoverEffect {
							cursor: default;
							&:hover {
								background-color: rgba($blueDark, 0.1);
								span {
									&:nth-child(2) {
										display: inline-block;	
									}
								}
							}
						}
						&.text {
							text-align: left;
							width: 20em;
							span {
								&:nth-child(2) {
									width: 30em;
								}
							}
						}
						&.option-1,
						&.option-2,
						&.option-3,
						&.option-4,
						&.option-5,
						&.option-6,
						&.option-7,
						&.option-8 {
							span {
								&:nth-child(2) {
									width: 10em;
									top: 1.4em;
								}
							}
						}
						&.image {
							span {
								&:nth-child(2) {
									width: 17em;
									top: 1.4em;
								}
							}
						}
					}
				}
			}
		}
		&.slider {
			.AdminTasks-header {
				.AdminTasks-title {
					&.text {
						text-align: left;
						width: 40em;
					}
					&.average {
						width: calc(100% - 40em);
					}
				}
			}
			.AdminTasks-body {
				.AdminTasks-row {
					.AdminTasks-value {
						&.text {
							text-align: left;
							width: 40em;
						}
						&.average {
							width: calc(100% - 40em);
						}
					}
				}
			}
		}
		&.sort {
			.AdminTasks-header {
				.AdminTasks-title {
					&.text {
						text-align: left;
						width: 20em;
					}
					
				}
			}
			.AdminTasks-body {
				.AdminTasks-row {
					.AdminTasks-value {
						&.hoverEffect {
							cursor: default;
							&:hover {
								background-color: rgba($blueDark, 0.1);
								span {
									&:nth-child(2) {
										display: inline-block;	
									}
								}
							}
						}
						&.text {
							text-align: left;
							width: 20em;
						}
					
					}
				}
			}
		}
		&.order {
			.AdminTasks-header {
				.AdminTasks-title {
					&.text {
						text-align: left;
						width: 20em;
					}
					
				}
			}
			.AdminTasks-body {
				.AdminTasks-row {
					.AdminTasks-value {
						&.hoverEffect {
							cursor: default;
							&:hover {
								background-color: rgba($blueDark, 0.1);
								span {
									&:nth-child(2) {
										display: inline-block;	
									}
								}
							}
						}
						&.text {
							text-align: left;
							width: 20em;
						}
					
					}
				}
			}
		}
	}
}