@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.ResultsKeywordsAll {
	position: relative;
	height: 100%;
	overflow: visible;
	
	.ResultsKeywordsAll-title {
		margin-bottom: 1em;
		span {
			font-size: 1.67em;
			font-weight: 900;
			color: $blueDark;
		}
	}

	.ResultsKeywordsAll-categories {
		position: relative;
		margin-left: -2em;
		margin-top: -1em;

		.ResultsKeywordsAll-category {
			position: absolute;
			top: 0;
			left: 0;
			width: 18.75em;
			height: 18.75em;
			border-radius: 100%;
			background-color: $white;
			@include flex('center', 'center');
			&.interests {
				left: calc(18.75em + 1.5em);
			}
			&.preferences {
				top: 17.5em;
				left: calc(0.5 * (18.75em + 1.5em));
			}
			.ResultsKeywordsAll-categoryWrap {
				width: 10em;
				.ResultsKeywordsAll-categoryTitle {
					span {
						font-size: 1.5em;
						font-weight: 900;
						color: $blueDark;
					}
				}
				.ResultsKeywordsAll-categoryKeywords {
					display: inline-block;
					.ResultsKeywordsAll-keyword {
						position: relative;
						height: 2em;
						width: 100%;
						padding-left: 1.75em;
						@include flex('flex-start', 'center');
						&::after {
							content: '';
							position: absolute;
							top: 0.4em;
							left: 0;
							width: 1em;
							height: 1em;
							border-radius: 100%;
						}
						&.skills::after {
							background-color: $blue;
						}
						&.preferences::after {
							background-color: $orange;
						}
						&.interests::after {
							background-color: $yellow;
						}
						span {
							display: inline-block;
							line-height: 1;
							font-size: 0.9em;
							font-weight: 600;
							color: $blueDark;
						}
					}
				}
			}
		}
	}

	.ResultsKeywordsAll-backBtn {
		position: absolute;
		top: 34.15em;
		left: -7.5em;
	}

	.ResultsKeywordsAll-continueBtn {
		position: absolute;
		top: 34.15em;
		right: 10em;
	}
}