@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.AdminStats {
	position: relative;
	width: 70em;
	padding-top: 2em;

	.AdminStats-filters {
		.AdminStats-filter {
			position: relative;
			background-color: $white;
			padding: 0.75em 1em;
			border-radius: 0.5em;
			margin-bottom: 1em;
			@include flex('flex-start', 'center');
			.AdminStats-filterName {
				width: 8em;
			}
			.AdminStats-filterOptions {
				.AdminStats-filterOption {				
					position: relative;
					display: inline-block;
					min-width: 8em;
					padding-left: 1.5em;
					cursor: pointer;
					@include no-select();
					span {
						font-weight: normal;
					}
					&::before {
						content: '';
						position: absolute;
						left: 0;
						top: calc(50% - 0.65em);
						width: 1em;
						height: 1em;
						border: 0.15em solid $blue;
					}
					&.selected::before {
						background-color: $blue;
					}
				}
			}

			&.classLevels {
				.AdminStats-filterOptions {
					.AdminStats-filterOption {
						min-width: 6em;
					}
				}
			}
			&.tags {
				.AdminStats-filterOptions {
					.AdminStats-filterOption {
						min-width: 6em;
						padding-right: 1.5em;
					}
				}
			}
		}	
	}

	.AdminStats-games {
		position: relative;
		background-color: $white;
		padding: 1em;
		border-radius: 0.5em;
		margin: 2em 0 2em 0;
		@include flex('flex-start', 'center');
		.AdminStats-gamesStarted,
		.AdminStats-gamesCompleted,
		.AdminStats-avrCompletionTime {
			@include no-select();
			width: 20em;
			line-height: 1;
			span {
				font-size: 1.25em;
				font-weight: normal;
				&:first-of-type {
					font-weight: bold;
					padding-right: 0.5em;
				}
			}
		}
	}

	.AdminStats-stats {
		position: relative;
		width: 100%;
	}
}