@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.ResetPassword {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: rgba($blueDark, 0.5);
	backdrop-filter: blur(1px);
	@include flex('center', 'center');
	
	.ResetPassword-dialogue {
		@include flex('center', 'center', 'column');
		background-color: $white;
		padding: 1em;
		border-radius: 0.5em;

		.ResetPassword-returnWrapper {
			width: 15em;
			@include flex('center', 'center', 'column');
			.ResetPassword-title {
				margin-bottom: 1em;

				span {
					font-size: 1.25em;
					font-weight: bold;
					color: $blueDark;
				}
			}
			.ResetPassword-message {
				a {
					color: $blueDark;
					text-transform: capitalize;
				}
			}
		}
							

		.ResetPassword-form {
			width: 15em;
			@include flex('center', 'center', 'column');
			.ResetPassword-header {
				margin-bottom: 1em;

				span {
					font-size: 1.25em;
					font-weight: bold;
					color: $blueDark;
				}
			}
			.ResetPassword-inputWrapper {
				width: 100%;
				@include flex('', '', 'column');
				margin-bottom: 0.5em;
				
				span {
					color: $blueDark;
					text-transform: capitalize;
				}

				.ResetPassword-input {
					width: 100%;
					font-size: 1em;
					font-weight: normal;
					height: 2em;
					padding-left: 0.5em;
					margin-top: 0.5em;
					border-style: solid;
					color: $blueDark;
					border-radius: 0.25em;
				}
			}
			.ResetPassword-loginButton {
				width: 10em;
				margin-top: 1em;
			}
			.ResetPassword-errorMessage {
				color: red;
				font-size: 0.8em;
			}
		}
	}
}