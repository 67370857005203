@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.KeywordCategory {
	position: absolute;
	@include flex('center', 'flex-start');
	
	&.active {
		top: -3em;
		left: 1em;
	}
	
	.KeywordCategory-bubble {
		position: relative;
		background-color: $white;
		width: 18.6em;
		height: 18.6em;
		border-radius: 100%;
		cursor: pointer;
		@include box-shadow(0em, 0.04em, 0.24em, 0.1em, rgba($black, 0.2));
		
		&:not(.active) {
			@include flex('center', 'center', 'column');

			&:hover {
				@include scale(1.1);
			}
		}
		@include transition('transform', 0.1);

		&.active {
			width: 35.6em;
			height: 35.6em;
		}

		&.inactive {
			@include flex('center', 'center');
		}
	
		.KeywordCategory-title {
			position: relative;
			@include no-select();
			span {
				font-size: 1.5em;
				font-weight: bold;
				color: $blueDark;
			}

			&.active {
				top: 8.33em;
				left: 5em;
				line-height: 1em;
			}
		}

		.KeywordCategory-description {
			text-align: center;
			margin-top: 0.5em;
			line-height: 1.2em;
			span {
				color: $blueDark;
				font-weight: 600;
			}
		}

		.KeywordCategory-moreBtn {
			position: absolute;
			bottom: 1.88em;
			width: 5em;
			height: 1.8em;
		}

		.KeywordCategory-bubbleContentWrapper {
			&.skills {
				@include fancy-scrollbar(0.3em, 100%, darken($white, 15%), 1em, 1em, 0.3em, $blue);
			}
			&.preferences {
				@include fancy-scrollbar(0.3em, 100%, darken($white, 15%), 1em, 1em, 0.3em, $orange);
			}
			&.interests {
				@include fancy-scrollbar(0.3em, 100%, darken($white, 15%), 1em, 1em, 0.3em, $yellow);
			}
			
			.KeywordCategory-keywordList {
				position: relative;
				top: 9.33em;
				left: 5em;
				height: 18em;
				width: 25em;
				overflow: auto;

				.KeywordCategory-keywordListItem {
					width: 20em;
					margin-bottom: 0.4em;
					line-height: 1.15em;
					@include flex('space-between', 'center');

					.KeywordCategory-keywordCircle {
						width: 1em;
						height: 1em;
						border-radius: 100%;
						
						&.skills {
							background-color: $blue;
						}
						&.preferences {
							background-color: $orange;
						}
						&.interests {
							background-color: $yellow;
						}
					}
					.KeywordCategory-keywordName {
						width: 15em;
						text-align: start;
						span {
							font-weight: 600;
							color: $blueDark;
						}
					}
						
					.KeywordCategory-keywordPercentage {
						width: 3em;
						text-align: end;
						span {
							font-weight: 600;
							color: $blueDark;
						}
					}
				}
			}

			.KeywordCategory-closeBtn {
				position: absolute;
				width: 2.1em;
				height: 2.1em;
				top: 5em;
				right: 3.33em;
				background-image: url(../../../assets/images/icons/icon-close.svg);
				background-size: contain;
				cursor: pointer;
				@include transition('transform', 0.1);
				
				&:hover {
					@include scale(1.1);
				}
			}
		}
	}
	
	.KeywordCategory-keywordWrapper {
		position: absolute;
		top: calc(18.6em / 2);

		// Place in center from top.
		@include flex('center', 'center');

		.KeywordCategory-line {
			position: absolute;
			right: calc(50%);
			top: calc(50% - 1em);
			height: 1em;
			bottom: 1em;
			border-bottom-style: solid;
			border-bottom-width: 0.15em;
			transform-origin: bottom right;

			@include growFromWidth(0.5s, 0s, 0em);

			&.skills {
				border-color: $blue;
			}
			&.preferences {
				border-color: $orange;
			}
			&.interests {
				border-color: $yellow;
			}
		}

		.KeywordCategory-keyword {
			position: absolute;
			width: 1em;
			height: 1em;
			border-radius: 100%;

			@include moveFromPoint(0.5s);

			&.skills {
				background-color: $blue;
			}
			&.preferences {
				background-color: $orange;
			}
			&.interests {
				background-color: $yellow;
			}

			.KeyWordCategory-name {
				position: absolute;
				padding-top: 0.5em;
				width: 7em;

				&.leftAlign {
					left: calc(50% - 5.5em);
					@include flex('flex-end');
				}

				&.rightAlign {
					right: calc(50% - 5.5em);
					@include flex('flex-start');
				}
				
				span {
					text-align: center;
					font-size: 0.8em;
					font-weight: bold;
					color: $blueDark;
				}
			}
		}
	}
}