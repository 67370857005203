@mixin circleTransition($seconds: 0.5s) {
	-webkit-animation: circleTransition $seconds ease-out forwards;
	animation: circleTransition $seconds ease-out forwards;
  transform-origin: center center;
}

@-webkit-keyframes circleTransition {
	0% {
    @include scale(1);
  }
  50% {
    @include scale(0.5);
  }
	100% {
    @include scale(1);
  }
}
@keyframes circleTransition {
	0% {
    @include scale(1);
  }
  50% {
    @include scale(0.5);
  }
	100% {
    @include scale(1);
  }
}

@mixin fadeIn($seconds: 0.5s) {
	-webkit-animation: fadeIn $seconds ease-out forwards;
	animation: fadeIn $seconds ease-out forwards;
  transform-origin: center center;
}

@-webkit-keyframes fadeIn {
	0% {
    @include opacity(0);
  }
	100% {
    @include opacity(1);
  }
}
@keyframes fadeIn {
	0% {
    @include opacity(0);
  }
	100% {
    @include opacity(1);
  }
}

@mixin moveFromPoint($seconds: 1s, $delay: 0s, $top: 0em, $left: 0em) {
  $animation-name: unique-id() !global;

  @-webkit-keyframes #{$animation-name} {
    0% {
      top: $top;
      left: $left;
      @include scale(0);
      @include opacity(1);
    }
    100% {
      @include scale(1);
      @include opacity(1);
    }
  }
  
  @keyframes #{$animation-name} {
    0% {
      top: $top;
      left: $left;
      @include scale(0);
      @include opacity(1);
    }
    100% {
      @include scale(1);
      @include opacity(1);
    }
  }

  -webkit-animation: $animation-name $seconds ease-out $delay 1 forwards;
  animation: $animation-name $seconds ease-out $delay 1 forwards;
}

@mixin growFromWidth($seconds: 1s, $delay: 0s, $fromWidth) {
  $animation-name: unique-id() !global;

  @-webkit-keyframes #{$animation-name} {
    0% {
      width: $fromWidth;
    }
    100% {
    }
  }
  
  @keyframes #{$animation-name} {
    0% {
      width: $fromWidth;
    }
    100% {
    }
  }

  -webkit-animation: $animation-name $seconds ease-out $delay 1 forwards;
  animation: $animation-name $seconds ease-out $delay 1 forwards;
}

@mixin growShrink($seconds: 1s, $delay: 0s) {
	-webkit-animation: growShrink $seconds ease-in-out $delay forwards;
	animation: growShrink $seconds ease-in-out $delay forwards;
  transform-origin: center center;
}

@-webkit-keyframes growShrink {
	0% {
    @include scale(0);
  }
	50% {
    @include scale(1);
  }
	100% {
    @include scale(0);
  }
}
@keyframes growShrink {
	0% {
    @include scale(0);
  }
	50% {
    @include scale(1);
  }
	100% {
    @include scale(0);
  }
}

@mixin flash($seconds: 1s, $delay: 0s, $iterations: 1, $fromColor: $blue, $toColor: $blueFlash) {
  $animation-name: unique-id() !global;

  @-webkit-keyframes #{$animation-name} {
    0% {
      background-color: $fromColor;
    }
    50% {
      background-color: $toColor;
    }
    100% {
      background-color: $fromColor;
    }
  }
  @keyframes #{$animation-name} {
    0% {
      background-color: $fromColor;
    }
    50% {
      background-color: $toColor;
    }
    100% {
      background-color: $fromColor;
    }
  }
	-webkit-animation: $animation-name $seconds ease-in-out $delay $iterations;
	animation: $animation-name $seconds ease-in-out $delay $iterations;
}

@mixin growShrinkWithPause($seconds: 2s, $delay: 0s) {
	-webkit-animation: growShrinkWithPause $seconds ease-in-out $delay infinite;
	animation: growShrinkWithPause $seconds ease-in-out $delay infinite;
  transform-origin: 10% 10%;
}

@-webkit-keyframes growShrinkWithPause {
	0% {
    @include scale(1);
  }
  17% {
    @include scale(1.15);
  }
  34% {
    @include scale(1.15);
  }
  50% {
    @include scale(1);
  }
	100% {
    @include scale(1);
  }
}
@keyframes growShrinkWithPause {
	0% {
    @include scale(1);
  }
  17% {
    @include scale(1.15);
  }
  34% {
    @include scale(1.15);
  }
  50% {
    @include scale(1);
  }
	100% {
    @include scale(1);
  }
}