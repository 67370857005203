@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.TaskNavigation {
	position: absolute;
	bottom: 0.5em;
	left: 0.5em;
	z-index: 10;

	.TaskNavigation-modules {
		height: 10em;
		overflow: auto;
		background: white;
		color: $blueDark;
		padding: 0.25em 0.5em;
		border-top-left-radius: 0.5em;
		border-top-right-radius: 0.5em;
		text-align: left;
		.TaskNavigation-module {
			padding: 0.5em 0;
			border-bottom: 1px dashed $blueDark;
			&:last-of-type {border-style: none;}
			.TaskNavigation-task {
				&.selected {background-color: rgba(black, 0.2);}
				span {
					font-size: 0.75em;
				}
			}
		}
		
	}

	.TaskNavigation-toggleBtn {
		width: 2em;
		height: 2em;
		background-image: url('../../../assets/images/icons/icon-menu.svg');
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
	}
}