@import '../../../../styles/fonts';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.PopupDeleteSchool {
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba($blueDark, 0.5);
	backdrop-filter: blur(1px);
	width: 100%;
	height: 100%;
	@include flex('center', 'center');

	.PopupDeleteSchool-popupWindow {
		width: 25em;
		background-color: $white;
		border-radius: 0.5em;
		padding: 1em;

		.PopupDeleteSchool-popupWindowHeader {
			margin-bottom: 1em;
			span {
				font-size: 1.2em;
				font-weight: bold;
			}
		}
		.PopupDeleteSchool-popupWindowText {
			margin-bottom: 0.5em;
			span {
				font-weight: 500;
				line-height: 1.2em;
			}
		}
		.PopupDeleteSchool-popupWindowButtons {
			@include flex('space-between');

			.PopupDeleteSchool-popupWindowButton {
				width: 10em;
			}
		}
		.PopupDeleteSchool-popupWindowErrorMessage {
			@include flex('center');
			margin-top: 0.75em;
			span {
				color: red;
			}
		}
	}
}