@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Facilitator {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 3em 1em 1em 26em;

	.Facilitator-addFacilitatorBtn {
		position: absolute;
		left: 2em;
		top: 12em;
		width: 16em;
	}


	.Facilitator-schools {
		position: absolute;
		left: 2em;
		top: 16em;
		background-color: $white;
		border-radius: 0.5em;
		padding: 1em;
		margin-bottom: 1em;
		width: 16em;
		max-height: 30em;
		overflow: auto;
		.Facilitator-schoolsTitle {
			margin-bottom: 0.5em;
			span {
				color: $blueDark;
				font-size: 1.25em;
				font-weight: bold;
				
			}
		}
		.Facilitator-school {
			position: relative;
			min-height: 2em;
			margin-bottom: 0.5em;
			padding-left: 1.5em;
			cursor: pointer;
			@include flex('flex-start', 'center');
			span {
				font-size: 1em;
				line-height: 1;
			}
			&::after {
				content: '';
				position: absolute;
				left: 0;
				top: calc(0.5em - 1px);
				height: 1em;
				width: 1em;
				border-radius: 100%;
				background-color: white;
				border: 1px solid $blueDark;
			}
			&.selected {
				&::after {
					background-color: $blueDark;
				}
			}
		}
	}


	
	.Facilitator-wrap {
		width: 60em;
		.Facilitator-filters {
			position: relative;
			background-color: $white;
			padding: 1em;
			border-radius: 0.5em;
			.Facilitator-filter {
				@include flex('space-between', 'center');
				.Facilitator-filterTitle {
					@include no-select();
					width: 5em;
				}
				.Facilitator-filterOptions {
					@include flex('flex-start', 'center');
					width: calc(100% - 7em);
					.Facilitator-filterOption {
						@include no-select();
						position: relative;
						width: 8em;
						padding-left: 1.5em;
						cursor: pointer;
						span {
							font-weight: normal;
						}
						&::before {
							content: '';
							position: absolute;
							left: 0;
							top: calc(50% - 0.65em);
							width: 1em;
							height: 1em;
							border: 0.15em solid $blue;
						}
						&.selected::before {
							background-color: $blue;
						}
					}
				}
			}
		}

		.Facilitator-schoolAndGamesInfo {
			position: relative;
			margin: 1em 0 2em 0;
			@include flex('space-between', 'flex-start');
			.Facilitator-schoolInfo,
			.Facilitator-gamesInfo {
				width: calc(50% - 0.5em);
				height: 5.7em;
				background-color: $white;
				padding: 1em;
				border-radius: 0.5em;
			}
			.Facilitator-schoolInfo {
				.Facilitator-schoolCode {
					line-height: 1;
					span {
						font-size: 1em;
						&:first-of-type {
							padding-right: 0.5em;
						}
					}
				}
				.Facilitator-downloadDiplomatBtn {
					margin-top: 0.5em;
					width: 16em;
				}
			}
			.Facilitator-gamesInfo {
				.Facilitator-gamesStarted,
				.Facilitator-gamesCompleted {
					@include no-select();
					@include flex('flex-start', 'center');
					width: 50%;
					height: 50%;
					line-height: 1;
					span {
						font-size: 1.1em;
						&:first-of-type {
							padding-right: 0.5em;
						}
					}
				}
			}
		}

		.Facilitator-stats {
			position: relative;
			width: 100%;
		}
	}

	.Facilitator-logoutButton {
		position: absolute;
		bottom: 1em;
		left: 2em;
		width: 5em;
	}
}
				