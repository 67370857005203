@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.FacilitatorPasswordReset {
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba($blueDark, 0.5);
	backdrop-filter: blur(1px);
	width: 100%;
	height: 100%;
	@include flex('center', 'center');
	
	.FacilitatorPasswordReset-content {
		width: 25em;
		height: 28em;
		background-color: $white;
		border-radius: 0.5em;
		padding: 1em;
	
		.FacilitatorPasswordReset-header {
			height: 4em;
			.FacilitatorPasswordReset-title {
				text-align: center;
				span {
					font-size: 1.25em;
					font-weight: bold;
					color: $blueDark;
				}
			}
		}

		.FacilitatorPasswordReset-body {
			padding: 0 2em;
			.FacilitatorPasswordReset-text {
				margin-bottom: 1em;
				span {
					font-weight: 400;
					color: $blueDark;
				}
			}
		}
		.FacilitatorPasswordReset-form {
			.FacilitatorPasswordReset-inputWrapper {
				width: 100%;
				margin-bottom: 1em;
				span {
					display: inline-block;
					color: $blueDark;
					text-transform: capitalize;
				}
	
				.FacilitatorPasswordReset-input {
					display: inline-block;
					width: 100%;
					height: 2em;
					font-size: 1em;
					font-weight: normal;
					padding-left: 0.5em;
					border-style: solid;
					color: $blueDark;
					border-radius: 0.25em;
				}
			}
			.FacilitatorPasswordReset-confirmButton {
				width: 8em;
				margin: 1em auto 0 auto;
			}
			.FacilitatorPasswordReset-errorMessage {
				height: 1em;
				margin-top: 1em;
				span {
					font-size: 0.9em;
					color: red;
				}
			}
		}

		.FacilitatorPasswordReset-goToPageBtn {
			margin-top: 2em;
			
		}
	}
}