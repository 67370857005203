@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.CalculateResults {
	.CalculateResults-circle {
		position: absolute;
		top: calc(50% - 18.6em / 2);
		left: calc(50% - 18.6em / 4);
		width: 18.6em;
		height: 18.6em;
		background-color: $white;
		border-radius: 100%;
		@include flex('center', 'center');


		.CalculateResults-dotsWrapper {
			position: relative;
			.CalculateResults-dot {
				position: absolute;
			}
		}

		.CalculateResults-title {
			position: absolute;

			span {
				font-size: 1.2em;
				font-weight: 900;
				color: $blueDark;
			}
		}
	}
}