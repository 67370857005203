@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.AdminSchools {
	position: relative;
	width: 70em;

	.AdminSchools-addSchoolButton {
		margin-bottom: 2em;
		span {
			color: $blueDark;
		}
	}

	.AdminSchools-filters {
		width: 100%;
		padding: 1em;

		background-color: $white;
		border-radius: 0.5em;
		@include box-shadow(0em, 0.08em, 0.5em, 0.15em, rgba($black, 0.2));
		margin-bottom: 2em;

		.AdminSchools-filterTitle {
			margin-bottom: 0.5em;
			span {
				font-weight: bold;
			}
		}
		.AdminSchools-tags {
			@include flex();
			flex-wrap: wrap;
			width: 100%;

			.AdminSchools-tagWrapper {
				@include flex();
				width: 15em;
				margin-right: 2em;
				margin-bottom: 0.5em;
				.AdminSchools-tagToggle {
					width: 1em;
					height: 1em;
					background-color: $white;
					border: 0.15em solid $blue;
					cursor: pointer;

					&.selected {
						background-color: $blue;
					}
				}
				.AdminSchools-tagName {
					margin-left: 1em;
				}
			}
		}
	}

	.AdminSchools-schools {
		background-color: $white;
		padding: 1em;
		border-radius: 0.5em;
		@include box-shadow(0em, 0.08em, 0.5em, 0.15em, rgba($black, 0.2));
		.AdminSchools-body {
			min-height: 5em;
			max-height: 30em;
			@include hide-scrollbar();
			overflow-y: hidden;
			overflow: auto;
		}
		.AdminSchools-row {
			height: 1.25em;
			margin-top: 0.75em 0;
			@include flex('space-between');
			&.header {
				height: 2em;
				border-bottom-style: solid;
				margin: 0 0 0.5em 0;
				.AdminSchools-cell span {
					font-weight: bold;
				}
			}
			&.body {
				@include flex('space-between', 'center');
				height: 2.75em;
				cursor: pointer;

				&:nth-child(odd) {
					background-color: rgba($blue, 0.2);
				}
				.AdminSchools-cell {
					@include flex('', 'center');
					&.leftAligned {
						text-align: left;
					}
					&.rightAligned {
						text-align: right;
					}
				}
			}
			.AdminSchools-cell {
				width: 12.5em;
				position: relative;
				@include no-select();
				&.name span {
					font-weight: bold;
					@include hide-scrollbar();
					overflow-x: hidden;
					overflow: auto;
				}
				&.facilitators, 
				&.created {
					width: 8em;
				}
				&.plays {
					width: 9em;
				}
				&.delete {
					width: 3em;
				}

				&.savePdf {
					width: 5em;
				}

				&.sortable {
					cursor: pointer;
					@include flex('flex-start', 'center');
					&.sort span:nth-of-type(2) {
						display: block;
						width: 1em;
						height: 1em;
						margin-left: 1em;
						background-image:url('../../../assets/images/icons/icon-arrow-down.svg');
					}
					&.ASC span:nth-of-type(2) {
						background-image:url('../../../assets/images/icons/icon-arrow-up.svg');
					}
				}				

				span {
					font-weight: normal;
					font-size: 1.1em;
					white-space: nowrap;
				}
				.AdminSchools-deleteButton {
					@include flex('', 'center');
					cursor: pointer;
					width: 5em;
					height: 1.25em;
					background-image: url('../../../assets/images/icons/icon-close.svg');
					&.isDisabled {
						@include opacity(0.25);
						pointer-events: none;
					}

					&:not(.isDisabled) {
						&:hover {
							@include scale(1.2);
						}
					}
				}
			}
		}
	}
}