@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PopupAddFacilitator {
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba($blueDark, 0.5);
	backdrop-filter: blur(1px);
	width: 100%;
	height: 100%;
	@include flex('center', 'center');

	.PopupAddFacilitator-content {
		position: relative;
		width: 60em;
		background-color: $white;
		border-radius: 0.5em;

		.PopupAddFacilitator-header {
			padding: 1em 2em;
			background-color: $blue;
			span {
				color: white;
				font-weight: bold;
				font-size: 1.25em;
			}
		}

		.PopupAddFacilitator-body {
			margin-bottom: 1em;
			padding: 1em 2em;
			.PopupAddFacilitator-title {
				font-size: 1.25em;
			}
			.PopupAddFacilitator-facilitators {
				position: relative;
				width: 100%;
				height: 20em;
				max-height: 20em;
				overflow: auto;
				.PopupAddFacilitator-facilitator {
					height: 2em;
					@include flex('space-between', 'center');
					&:nth-child(even) {
						background-color: rgba($blue, 0.2);
					}
					.PopupAddFacilitator-email,
					.PopupAddFacilitator-schools {
						padding: 0 0.5em;
						white-space: nowrap;
						span {
							font-weight: normal;
						}
					}
				}
			}
		
			.PopupAddFacilitator-addFacilitator {
				width: 100%;
				.PopupAddFacilitator-addFacilitatorWrap {
					width: 100%;
					@include flex('space-between', 'flex-start');
					.PopupAddFacilitator-addFacilitatorInput {
						width: 20em;
						height: 2em;
						input {
							font-size: 1em;
							font-weight: 400;
							width: 100%;
							height: 100%;
							padding-left: 0.5em;
							border: 1px solid black;
							border-radius: 0.25em;
						}
					}
				}
				.PopupAddFacilitator-schools {
					margin: 0.75em 0;
					width: 40em;
					.PopupAddFacilitator-school {
						position: relative;
						display: inline-block;
						margin-right: 1em;
						padding-left: 1.5em;
						cursor: pointer;
						@include no-select();
						span {
							font-weight: normal;
						}
						&::before {
							content: '';
							position: absolute;
							left: 0;
							top: calc(50% - 0.65em);
							width: 1em;
							height: 1em;
							border: 0.15em solid $blue;
						}
						&.selected::before {
							background-color: $blue;
						}
					}
				}
				.PopupAddFacilitator-addFacilitatorErr {
					position: absolute;
					right: 2em;
					height: 1em;
					margin: 0.5em 0 0.75em 0;
					text-align: right;
					span {
						font-size: 0.8em;
						color: red;
					}
				}
				
				.PopupAddFacilitator-addFacilitatorButton {
					width: 10em;
					height: 2em;
				}
			}			
			.PopupAddFacilitator-buttons {
				margin-top: 2em;
				text-align: center;
				.PopupAddFacilitator-button {
					display: inline-block;
					width: 10em;
				}
			}
		}		
	}
}