@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.TaskIntro {
	position: relative;
	width: 36.33em;
	pointer-events: none;
	.TaskIntro-title {
		margin-bottom: 1em;
		span {
			font-size: 1.67em;
			font-weight: 900;
			color: $blueDark;
		}
	}
	.TaskIntro-text {
		border-radius: 1.1em;
		color: $textDark;
		margin-bottom: 2em;
		p {
			margin-top: 0;
			font-weight: 600;
			line-height: 1.5;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		span {
			display: inline-block;
			margin-top: 1em;
			font-weight: 900;
		}
	}

	.TaskIntro-image {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
}
