@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.StatsTable {	
	width: 100%;
	.StatsTable-nav {
		position: relative;
		width: 100%;
		overflow: hidden;
		@include flex('space-between');
		.StatsTable-navItem {
			width: 19em;
			height: 2.5em;
			background-color: $white;
			border-top-left-radius: 0.5em;
			border-top-right-radius: 0.5em;
			cursor: pointer;
			@include flex('center', 'center');
			@include opacity(0.5);
			span {
				font-size: 1.25em;
			}
			&:hover {
				@include opacity(0.75);
			}
			&.selected {
				@include opacity(1);
			}
		}
	}

	.StatsTable-table {
		background-color: $white;
		padding: 1em;
		border-bottom-left-radius: 0.5em;
		border-bottom-right-radius: 0.5em;
		.StatsTable-header {
			@include flex('space-between');
			border-bottom-color: $blueDark;
			border-bottom-style: solid;
			border-width: 0.1em;
			padding-bottom: 0.5em;
			margin-bottom: 0.5em;
			padding-left: 1em;
			.StatsTable-title {
				position: relative;
				width: 10em;
				text-align: left;
				cursor: pointer;
				span {
					color: $blueDark;
					font-size: 1.125em;
					font-weight: bold;
					@include no-select();
				}
	
				&:first-child {
					width: calc(100% - 30em);
					text-align: left;
					cursor: default;
				}
	
				&.sort {
					&::after {
						content: '';
						display: block;
						position: absolute;
						width: 1em;
						height: 1em;
						top: 0.5em;
						right: calc(100% + 0.5em);
						background-image:url('../../../assets/images/icons/icon-arrow-down.svg');
					}
					&.ASC::after {
						background-image:url('../../../assets/images/icons/icon-arrow-up.svg');
					}
				}
			}
		}
	
		.StatsTable-body {
			height: 21.5em;
			padding-left: 1em;
			overflow: auto;
			@include hide-scrollbar();
	
			.StatsTable-row {
				&:nth-child(odd) {
					background-color: rgba($blue, 0.2);
				}
				@include flex('space-between');			
				.StatsTable-value {
					width: 10em;
					text-align: left;
					span {
						color: $blueDark;
						line-height: 1.5em;
						font-weight: 600;
						&:nth-child(2) {
							font-weight: 400;
							padding-left: 0.2em;
						}
					}
					&:first-child {
						width: calc(100% - 30em);
						@include flex('flex-start', 'center');
					}
				}
			}
		}
	
	
		&.keywords {
			.StatsTable-header {
				.StatsTable-title {
					width: 15em;
				}
			}
			.StatsTable-body {
				.StatsTable-row .StatsTable-value {
					width: 15em;
				}
			}
		}
	}
}