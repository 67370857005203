@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.TaskCounter {
	position: relative;

	@include fadeIn(1s);

	.TaskCounter-debuggerCircleCenter {
		position: absolute;
		width: 1em;
		height: 1em;
		background-color: red;
		border-radius: 2em;
		z-index: 2;
	}
}