@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.LandingPage {
	width: 100%;
	height: 100%;
	
	.LandingPage-flowTextWrapper {
		position: absolute;
		top: calc(24em);
		left: calc(50% - 3em);
		.LandingPage-flowText {
			text-align: center;
			color: $blueDark;
			line-height: 1.5em;
			width: 19.5em;
			text-align: left;
			span {
				font-size: 1.25em;
				font-weight: 600;
			}
			&.topPadded {
				padding-top: 1.5em;
			}
			&.bold {
				span {
					font-weight: bold;
				}
			}
		}
	}

	.LandingPage-gameButtons {
		position: absolute;
		top: 34.5em;
		left: 52.6em;

		&.twoButtons {
			@include flex();
			top: 32.5em;
			left: 44em;
		}

		.LandingPage-resetGame {
			margin-right: 0.6em;
		}
	}

	.LandingPage-toggleCookiePopup {
		position: absolute;
		left: 2em;
		bottom: 2em;
		width: 3em;
		height: 3em;
		background-image: url('../../assets/images/icons/icon-cookie.svg');
		cursor: pointer;
	}

	.LandingPage-resetGameBtn {
		position: absolute;
		bottom: 1em;
		right: 1em;
	}
}