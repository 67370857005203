@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Slider {
	position: relative;
	height: 100%;

	.Slider-intro {
		position: relative;
		width: 17.5em;
	}

	.Slider-doneBtn {
		position: absolute;
		top: 34.15em;
		right: 10em;
	}
	
	.Slider-previousButton {
		position: absolute;
		top: 34.15em;
		left: -6.75em;
	}

	.Slider-sliderWrapper {
		@include no-select();
		position: absolute;
		width: 37em;
		padding: 1em 1.5em;
		overflow: auto;
		@include hide-scrollbar();
		@include flex('','','column');

		.Slider-inputSlider {
			@include appearance(none);
			background: transparent;
			background-image: linear-gradient(to right,
				rgba($white, 0),
				$white,
				rgba($white, 0),
			);

			height: 1em;
			border-style: solid;
			border-radius: 0.25em;
			border-width: 0.17em;
			border-color: $blueDark;
		}

		.Slider-inputSlider::-moz-range-thumb {
			background-color: transparent;
			border-color: transparent;
			@include appearance(none);
			@include transition('transform', 0.1);
			height: 2.4em;
			width: 1.25em;
			background-image: url(../../../assets/images/icons/icon-slider-thumb.svg);
			background-size: contain;
			background-repeat: no-repeat;
			cursor: pointer;
			&:hover {
				@include scale(1.2);
			}
		}

		.Slider-inputSlider::-webkit-slider-thumb {
			@include appearance(none);
			@include transition('transform', 0.1);
			height: 2.4em;
			width: 1.25em;
			background-image: url(../../../assets/images/icons/icon-slider-thumb.svg);
			background-size: contain;
			background-repeat: no-repeat;
			cursor: pointer;
			&:hover {
				@include scale(1.2);
			}
		}

		.Slider-textWrapper {
			width: 100%;
			@include no-select();
			@include flex('space-between','space-between');
			
			.Slider-text {
				line-height: 1em;
				margin-top: 1.5em;

				span {
					font-size: 1em;
					color: $blueDark;
					font-weight: bold;
				}

				&.minimum {
					text-align: start;
				}

				&.maximum {
					text-align: end;
				}
			}
		}
	}
}