@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';

.InputSearch {
	position: relative;
	width: 100%;
	.InputSearch-schoolInput {
		width: 100%;
		height: 2.5em;
		padding-left: 0.5em;
		border-radius: 0.25em;
		border-style: solid;
		border-color: $green;
		border-width: 0.15em;
		color: $blueDark;
	}

	.InputSearch-optionsWrapper {
		width: 100%;
		max-height: 5em;
		overflow-y: hidden;
		position: absolute;
		z-index: 3;
		background-color: $white;
		border-radius: 0.25em;
		border-style: solid;
		border-color: $green;
		border-width: 0.15em;

		.InputSearch-option {
			padding: 0.25em;
			padding-left: 0.5em;
			cursor: pointer;

			&:hover {
				background-color: darken($white, 10%);
			}

			&.focus {
				background-color: $green;
			}
		}
	}
}

