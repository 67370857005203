@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.FacilitatorCreateUser {
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba($blueDark, 0.5);
	backdrop-filter: blur(1px);
	width: 100%;
	height: 100%;
	@include flex('center', 'center');

	.FacilitatorCreateUser-content {
		width: 25em;
		height: 28em;
		background-color: $white;
		border-radius: 0.5em;
		padding: 1em;

		.FacilitatorCreateUser-header {
			height: 4em;
			.FacilitatorCreateUser-title {
				text-align: center;
				span {
					font-size: 1.25em;
					font-weight: bold;
					color: $blueDark;
				}
			}
		}

		.FacilitatorCreateUser-body {
			padding: 0 2em;
			.FacilitatorCreateUser-text {
				margin-bottom: 1em;
				span {
					font-weight: 400;
					color: $blueDark;
				}
			}
			.FacilitatorCreateUser-form {
				.FacilitatorCreateUser-inputWrapper {
					width: 100%;
					margin-bottom: 1em;
					span {
						display: inline-block;
						color: $blueDark;
						text-transform: capitalize;
					}
					.FacilitatorCreateUser-input {
						display: inline-block;
						width: 100%;
						height: 2em;
						font-size: 1em;
						font-weight: normal;
						padding-left: 0.5em;
						border-style: solid;
						color: $blueDark;
						border-radius: 0.25em;
					}
				}
				.FacilitatorCreateUser-confirmBtn {
					width: 12em;
					margin: 1em auto 0 auto;
				}
				.FacilitatorCreateUser-errorMessage {
					height: 1em;
					margin-top: 1em;
					line-height: 1;
					span {
						font-size: 0.9em;
						color: red;
					}
				}
			}	
			.FacilitatorCreateUser-goToPageBtn {
				margin-top: 1em;
			}
		}
	}
}