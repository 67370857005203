@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.ResultsOverview {
	position: relative;
	height: 100%;
	overflow: visible;

	.ResultsOverview-keywords {
		@include flex();
		padding-top: 2.5em;

		.ResultsOverview-keywordsTitle {
			position: absolute;
			top: 0;
			line-height: 1em;

			span {
				white-space: nowrap;
				font-size: 1.7em;
				color: $blueDark;
				font-weight: 900;
			}
		}

		.ResultsOverview-category {
			position: relative;
			@include flex('','','column');
			width: 14.8em;
			height: 14.8em;
			margin-left: -1.85em;
			padding-top: 3.12em;
			padding-left: 3.12em;
			border-radius: 100%;
			background-color: $white;
			
			&.skills {
				z-index: 3;	
			}

			&.interests {
				z-index: 2;
				left: -1em;
			}

			&.preferences {
				left: -2em;
			}

			.ResultsOverview-categoryTitle {
				position: relative;
				line-height: 1.1em;
				span {
					color: $blueDark;
					font-size: 1.33em;
					font-weight: 800;
				}
			}

			.ResultsOverview-keywordsWrapper {
				position: relative;
				margin-top: 0.5em;

				.ResultsOverview-keyword {
					position: relative;
					left: -0.5em;
					margin-bottom: 0.3em;
					@include flex('', 'center');

					.ResultsOverview-point {
						position: relative;
						min-width: 0.9em;
						height: 0.9em;
						border-radius: 100%;
						margin-left: 0.3em;
						margin-right: 0.5em;

						&.skills {
							background-color: $blue;
						}

						&.interests {
							background-color: $yellow;
						}

						&.preferences {
							background-color: $orange;
						}
					}
					.ResultsOverview-keywordName {
						line-height: 0.7em;
						span {
							white-space: nowrap;
							color: $blueDark;
							font-size: 0.7em;
						}
					}
				}
			}
		}
	}

	.ResultsOverview-career {
		@include flex('', '', 'column');
		margin-top: 1em;
		.ResultsOverview-careersTitle {
			position: relative;
			line-height: 1em;
			span {
				white-space: nowrap;
				font-size: 1.7em;
				color: $blueDark;
				font-weight: 900;
			}
		}
		.ResultsOverview-careers {
			@include flex('', 'center');
			.ResultsOverview-careerBubble {
				@include flex('center', 'center');
				width: 14.3em;
				height: 14.3em;
				border-radius: 100%;
				background-color: $white;
				margin-top: 0.2em;
				margin-right: 0.8em;
				margin-left: -0.5em;
				overflow-wrap: anywhere;
				padding: 0.5em;
				line-height: 1.25;

				&.number-1 {
					@include scale(0.95);
				}
				&.number-2 {
					@include scale(0.85);
					margin-left: -1.25em;
				}

				span {
					text-align: center;
					font-size: 1.5em;
					font-weight: 900;
					color: $blueDark;
				}
			}
		}
	}

	.ResultsOverview-nextSteps {
		position: absolute;
		top: 0;
		right: 0em;
		.ResultsOverview-resultTitle {
			line-height: 1em;
			span {
				white-space: nowrap;
				font-size: 1.6em;
				color: $blueDark;
				font-weight: 900;
			}
		}
		.ResultsOverview-nextStep {
			position: absolute;
			background-color: $white;
			border-radius: 100%;
			@include flex('center', 'center', 'column');

			&.number-0 {
				left: 6em;
				top: 3.1em;
				height: 10.3em;
				width: 10.3em;
				
				span {
					font-size: 1.6em;
				}
			}
			&.number-1 {
				top: 14.3em;
				left: 9em;
				height: 8.2em;
				width: 8.2em;

				span {
					font-size: 1.4em;
				}
			}
			&.number-2 {
				left: 8.5em;
				top: 23.5em;
				height: 6.6em;
				width: 6.6em;

				span {
					font-size: 0.9em;
				}
			}

			span {
				font-size: 1.7em;
				text-align: center;
				font-weight: 800;
				color: $blueDark;
			}

			.ResultsOverview-nextStepSubtitle {
				text-align: center;
				line-height: 1.1em;
				span {
					font-size: 0.85em;
					font-weight: 600;
				}

				&.number-1 {
					line-height: 0.8em;
					span {
						font-size: 0.70em;
					}
				}

				&.number-2 {
					line-height: 0.7em;
					span {
						font-size: 0.60em;
					}
				}
			}
		}
	}
	
	.ResultsOverview-backBtn {
		position: absolute;
		top: 34.15em;
		left: -7.5em;
	}

	.ResultsOverview-pdfButton {
		position: absolute;
		top: 34.15em;
		right: 1em;

		a {
			text-decoration: none;
		}
	}
}