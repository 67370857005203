@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Sort {
	position: relative;
	height: 100%;
	width: 100%;

	.Sort-intro {
		position: relative;
		width: 17.5em;
	}

	.Sort-taskWrapper {
		@include no-select();
		@include flex();

		.Sort-categories {
			width: 25.5em;
			margin-right: 1.5em;
			margin-left: 3em;

			.Sort-category {
				margin-bottom: 0.25em;
				border-radius: 0.25em;
				background-color: rgba($white, 0.5);
				.Sort-categoryTitle {
					@include no-select();
					@include flex('', 'center');
					padding-left: 1em;
					padding-top: 0.5em;
					padding-bottom: 0.5em;
					color: $white;
					background-color: $blueDark;
					border-top-left-radius: 0.25em;
					border-top-right-radius: 0.25em;
					span {
						font-size: 0.88em;
						font-weight: 700;
					}
				}
			}
		}
		.Sort-items {
			width: 17.5em;
		}
	}
	.Sort-doneBtn{
		position: absolute;
		top: 34.15em;
		right: 10em;
	}
	.Sort-previousButton {
		position: absolute;
		top: 34.15em;
		left: -6.75em;
	}
}