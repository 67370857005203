@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.SortDndItem {
	position: relative;
	display: inline-block;
	border-radius: 0.25em;
	color: $white;
	background-color: $blue;
	padding: 0.65em 2.25em 0.5em 1em;
	height: 1.95em;
	margin-bottom: 0.75em;
	margin-right: 0.45em;
	line-height: 0.88em;
	white-space: nowrap;
	@include box-shadow(0, 0.3em, 0, 0, $blueDark);
	@include transition('transform', 0.1);

	&::before {
		content: '';
		background-image: linear-gradient(to bottom, rgba($white, 0.35), rgba($white, 0.1));
		height: 50%;
		width: 100%;
		border-top-left-radius: 0.25em;
		border-top-right-radius: 0.25em;
		position: absolute;
		top: 0;
		left: 0;
	}
	&:hover {
		cursor: move;
		@include scale(1.1);
	}
	span {
		font-size: 0.88em;
	}
	&.completed {
		cursor: not-allowed;
	}
	.SortDndItem-handle {
		position: absolute;
		height: 100%;
		width: 2em;
		right: 0;
		top: 0;
		background-image: url('../../../assets/images/icons/icon-move.svg');
		background-position: center right 0.5em;
		background-size: auto 1.25em;
		&.disabled {
			cursor: not-allowed;
			background-image: none;
		}
	}
}