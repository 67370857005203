@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Order {
	position: relative;
	height: 100%;
	.Order-content {
		position: relative;
		.Order-intro {
			position: relative;
			width: 100%;
			margin-bottom: 1em;
		}

		.Order-backgroundContainer {
			position: absolute;
			@include no-select();
			top: 3em;
			left: -3em;
			width: 100%;
			height: 100%;
		}

		.Order-items {
			position: relative;
			pointer-events: none;
			width: 100%;
			&.size-3 {height: calc(3 * 3em + 2 * 1.2em);}
			&.size-4 {height: calc(4 * 3em + 3 * 1.2em);}
			&.size-5 {height: calc(5 * 3em + 4 * 1.2em);}
			&.size-6 {height: calc(6 * 3em + 5 * 1.2em);}
			
			.Order-container {
				position: absolute;
				pointer-events: all;
				top: 0;
				height: 3em;
				@include flex('flex-start', 'center');
				.Order-containerNumber {
					@include no-select();
					pointer-events: none;
					display: inline-block;
					background-color: $blueDark;
					width: 3.46em;
					height: 3em;
					border-radius: 0.25em;
					margin-right: 0.46em;
					@include flex('center', 'center');
					span {
						pointer-events: none;
						color: white;
						font-size: 1.17em;
						font-weight: bold;
					}
				}
				&.pos-2 {top: 4.2em;}
				&.pos-3 {top: calc(2 * 4.2em);}
				&.pos-4 {top: calc(3 * 4.2em);}
				&.pos-5 {top: calc(4 * 4.2em);}
				&.pos-6 {top: calc(5 * 4.2em);}
				&.final {
					left: 0;
					width: 20.37em;

					&::after {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						width: 16.91em;
						height: 3em;
						border-radius: 0.25em;
						pointer-events: none;
						background-color: rgba($blueDark, 0.3);
					}
				}
				&.initial {
					left: 21.5em;
					width: 16.91em;
					border-radius: 0.25em;
					background-color: rgba($blueDark, 0.3);
				}
			}
			.Order-item {
				position: absolute;
				pointer-events: all;
				top: 0;
				left: 2.5em;
				width: 16.91em;
				height: 3em;
				@include flex('center', 'center');
				@include no-select();
				&.pos-2 {top: 4.2em;}
				&.pos-3 {top: calc(2 * 4.2em);}
				&.pos-4 {top: calc(3 * 4.2em);}
				&.pos-5 {top: calc(4 * 4.2em);}
				&.pos-6 {top: calc(5 * 4.2em);}
				&.final {
					@include translate(1em);
					
				}
				&.initial {
					@include translate(19em);
					width: 16.91em;
				}
			}
		}
		.Order-doneBtn {
			position: absolute;
			right: 10em;
			top: 34.15em;
		}
		.Order-previousButton {
			position: absolute;
			top: 34.15em;
			left: -6.75em;
		}
	}
}