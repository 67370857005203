@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.ResultsCareers {
	position: relative;
	height: 100%;
	overflow: visible;
	
	.ResultsCareers-title {
		@include no-select();
		margin-bottom: 1em;
		span {
			font-size: 1.67em;
			font-weight: 900;
			color: $blueDark;
		}
	}
	.ResultsCareers-subtitle {
		position: relative;
		p {
			line-height: 1.2em;
			color: $blueDark;
		}
	}

	.ResultsCareers-nextButton {
		position: absolute;
		width: 2.5em;
		height: 2.5em;
		bottom: 1.7em;
		left: calc(50% - 16.3em / 2);
		background-image: url(../../../assets/images/icons/icon-next.svg);
		background-size: contain;
		border-radius: 100%;
		@include box-shadow(0em, 0.08em, 0.5em, 0.15em, rgba($black, 0.2));
		@include transition('transform', 0.1);
		cursor: pointer;

		&:hover {
			@include scale(1.1);
		}

		&.previous {
			background-image: url(../../../assets/images/icons/icon-previous.svg);
			top: 1.7em;
		}
	}

	.ResultsCareers-backBtn {
		position: absolute;
		top: 34.15em;
		left: -7.5em;
	}

	.ResultsCareers-doneBtn {
		position: absolute;
		top: 34.15em;
		right: 10em;
	}
}