@import '../../../../styles/fonts';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.PopupAddSchool {
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba($blueDark, 0.5);
	backdrop-filter: blur(1px);
	width: 100%;
	height: 100%;
	@include flex('center', 'center');

	.PopupAddSchool-content {
		width: 25em;
		background-color: $white;
		border-radius: 0.5em;
		padding: 1em;

		.PopupAddSchool-header {
			margin-bottom: 1em;
			span {
				font-weight: bold;
				font-size: 1.25em;
			}
		}
		.PopupAddSchool-body {
			margin-bottom: 1em;
			span {
				font-weight: 400;
			}
			.PopupAddSchool-inputLabel {
				span {
					font-weight: bold;
				}
				&.small {
					line-height: 1;
					span {
						font-weight: normal;
						font-size: 0.7em;
					}
				}
			}
			.PopupAddSchool-input {
				width: 100%;
				height: 2em;
				margin-bottom: 1em;
				input {
					font-size: 1em;
					font-weight: 400;
					width: 100%;
					height: 100%;
					padding-left: 0.5em;
					border: 1px solid black;
					border-radius: 0.25em;
				}
			}
			.PopupAddSchool-errMsg {
				@include flex('center');
				height: 3.5em;
				margin-top: 0.75em;
				line-height: 1;
				span {
					color: red;
				}
			}
			.PopupAddSchool-buttons {
				@include flex('space-between');
				.PopupAddSchool-button {
					width: 10em;
				}
			}

			.PopupAddSchool-facilitators {
				margin: 1em 0 0 1em;
			}

			.PopupAddSchool-tags {
				margin: 1em 0 0 1em;
				width: 12em;
				.PopupAddSchool-tag {
					@include flex('space-between', 'center');
					// .PopupAddSchool-tagValue {
					// }
					.PopupAddSchool-removeTagBtn {
						width: 1.25em;
						height: 1.25em;
						background-image: url('../../../../assets/images/icons/icon-close.svg');
						cursor: pointer;
					}
				}
			}
		}		
	}
}