@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.CalculatingDot {
	border-radius: 100%;
	@include scale(0);
	@include growShrink(1.5s, 0s);

	&.blue {
		background-color: $blue;
	}

	&.yellow {
		background-color: $yellow;
	}

	&.orange {
		background-color: $orange;
	}
}