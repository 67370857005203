@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Module {
	width: 100%;
	height: 100%;
	.ModuleWrapper {
		width: 100%;
		height: 100%;
		padding: 5em 1em 1em 26em;
		padding-top: 5em;
		@include transition('opacity', 0.5);
		@include opacity(1);
		@include flex('flex-start', 'flex-start');
		&.hidden {
			@include opacity(0);
		}

		.Module-task {
			width: 100%;
			height: 100%;
		}

		.Module-startNewTestBtn {
			position: absolute;
			top: 39.2em;
			left: 60.65em;
		}

		.Module-keywordsPopupBtn,
		.Module-resultsPopupBtn {
			position: absolute;
			bottom: 0.25em;
			left: 5em;
			color: $white;
			text-decoration: underline;
			cursor: pointer;
		}
		.Module-resultsPopupBtn {
			left: 12em;
		}
	}
}