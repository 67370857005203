@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Background {
	@include no-select();
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: $green;
	overflow: hidden;

	.Background-gameLogo {
		position: absolute;
		overflow: visible;
		@include transition('all', 1);

		&.large {
			top: 1.7em;
			left: 14.88em;
			width: 30.8em;
			height: 14.3em;
		}
		&.animated {
			#circle-1 {
				@include growShrinkWithPause(2s, 1.36s);
			}
			#circle-2 {
				@include growShrinkWithPause(2s, 0.68s);
			}
			#circle-3 {
				@include growShrinkWithPause(2s, 0s);
			}
		}
		&.small {
			top: 0.8em;
			left: 4.08em;
			width: 17.5em;
			height: 8.11em;
		}
	}

	.Background-blueMargin {
		position: relative;
		width: 38em;
		height: 100%;
		background-color: $blueDark;

		clip-path: polygon(-1% 0, 82% 0%, 100% 100%, -1% 100%);

		@include transition('width', 0.5);

		&.small {
			width: 23em;
		}

		&.large {
			width: 38em;
		}

		.Background-blueCircle {
			position: absolute;
			bottom: -7.7em;
			left: -6em;
			width: 33em;
			height: 33em;
			border-style: solid;
			border-color: $blue;
			border-radius: 100%;
			border-width: 0.06em;
			@include transition('opacity', 0.5);
	
			&.inactive {
				@include opacity(0);
			}
		}
	}

	.Background-centerCircle {
		position: absolute;
		border-radius: 100%;
		background-color: rgba($white, 0.6);
		mix-blend-mode: overlay;
		@include transition('all', 0.5);

		&.small {
			top: 14em;
			left: 31em;
			width: 26.4em;
			height: 26.4em;
		}

		&.large {
			top: 2em;
			left: 18.5em;
			width: 50em;
			height: 50em;
		}

		&.animate {
			@include circleTransition(1s);
		}
	}

	.Background-fragmentedCircle {
		position: absolute;
		top: 3.5em;
		left: 56.4em;
		width: 18.4em;
		height: 18.4em;
		background-image: url(../../../assets/images/broken-circle.svg);
		background-size: contain;
		@include transition('opacity', 0.5);

		&.inactive {
			@include opacity(0);
		}
	}
}