@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PopupKeywords {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($blueDark, 0.2);
	backdrop-filter: blur(1px);
	z-index: 15;
	@include flex('center', 'center');

	.PopupKeywords-content {
		position: relative;
		width: 80em;
		background-color: white;
		border-radius: 0.33em;
		border: solid 0.33em $blue;
		padding: 2em;
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba(black, 0.2));

		.PopupKeywords-closeBtn {
			cursor: pointer;
			position: absolute;
			right: 0.25em;
			top: 0.25em;
			width: 1.75em;
			height: 1.75em;
			background-image: url('../../../assets/images/icons/icon-close.svg');
		}

		.PopupKeywords-text {
			color: $blueDark;
			column-count: 3;
			.PopupKeywords-keyword {
				span {
					display: inline-block;
					font-size: 0.85em;
					line-height: 1;
					&:first-of-type {
						width: 17em;
					}
				}
			}
		}
	}
}