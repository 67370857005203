@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.SortDndContainer {
	position: relative;
	min-height: 2.85em;
	padding: 0.5em;
	border: 0.16em solid $blueDark;
	border-top-style: none;
	border-bottom-left-radius: 0.25em;
	border-bottom-right-radius: 0.25em;
	&.hover {
		background-color: $white;
	}
}