@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.TaskCircle {
	position: absolute;
	width: 0.63em;
	height: 0.63em;
	background-color: $blue;
	border-radius: 100%;
	@include transitionMultiple('transform', 'background-color', 1);

	&.current {
		background-color: $white;
	}

	&.big {
		@include scale(1.5);
	}
}