@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Button {
	position: relative;
	width: 100%;
	height: 2.25em;
	border-radius: 0.55em;
	font-weight: 800;
	text-align: center;
	text-decoration: none;
	line-height: 1;
	padding-top: 0.1em;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	@include flex('center', 'center');
	span {
		display: inline-block;
	}
	&.disabled,
	&.locked {
		opacity: 0.4;
		cursor: not-allowed;		
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&.loading {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-loading.svg');
			background-size: auto 80%;
			background-position: right 1em center;
		}	
	}

	&.link {
		color: inherit;
	}
	&.submit {
		appearance: none;
		border-style: none;
		width: 8.33em;
		height: 2.5em;

		font-size: inherit;
	}

	&.blueBorder {
		border-style: solid;
		border-color: $blue;
		border-width: 0.1em;
	}

	&.rounded {
		border-radius: 2em;
	}

	&.tab {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	&.variableHeight {
		height: 100%;
	}

	&.smallFontSize {
		span {
			font-size: 0.75em;
		}
	}

	&.responsive {
		@include transition('transform', 0.1);
		&:hover,
		&:focus,
		&:active {
			&:not(.disabled) {
				@include scale(1.1);
			}
		}
	}

	&.bold {
		span {
			font-weight: 800;
		}
	}

	&.small {
		height: 1.67em;
		padding-top: 0.05em;
		span {
			font-size: 0.75em;
		}
	}

	&.white {
		background-color: $white;
		span {
			color: $blueDark;
		}
	}

	&.darkBlueText {
		span {
			color: $blueDark;
		}
	}

	&.blueText {
		span {
			color: $blue;
		}
	}

	&.shadow {
		@include box-shadow(0em, 0.08em, 0.5em, 0.15em, rgba($black, 0.2));
	}

	&.blue {
		background-color: $blue;
		span {
			color: $white;
		}
	}

	&.skills {
		background-color: $blue;
		span {
			color: $white;
		}
	}
	&.preferences {
		background-color: $orange;
		span {
			color: $white;
		}
	}
	&.interests {
		background-color: $yellow;
		span {
			color: $white;
		}
	}

	&.confirmTask {
		width: 8.33em;
		height: 2.5em;
		padding-top: 0.2em;
		border-radius: 1.25em;
		span {
			font-size: 1.08em;
			font-weight: 800;
		}
	}

	&.fullWidth {
		width: 100%;
	}

	// NEXT STEP BUTTON COLORS
	&.stx {
		background-color: $stx;
		span {
			color: $white;
			font-weight: bold;
		}
	}

	&.hhx {
		background-color: $hhx;
		span {
			color: $white;
			font-weight: bold;
		}
	}

	&.htx {
		background-color: $htx;
		span {
			color: $white;
			font-weight: bold;
		}
	}
	
	&.eud-technology {
		background-color: $eud-technology;
		span {
			color: $white;
			font-weight: bold;
		}
	}

	&.eud-health {
		background-color: $eud-health;
		span {
			color: $white;
			font-weight: bold;
		}
	}

	&.eud-office {
		background-color: $eud-office;
		span {
			color: $white;
			font-weight: bold;
		}
	}

	&.eud-food {
		background-color: $eud-food;
		span {
			color: $white;
			font-weight: bold;
		}
	}
	
	&.eux-technology {
		background-color: $eux-technology;
		span {
			color: $white;
			font-weight: bold;
		}
	}

	&.eux-health {
		background-color: $eux-health;
		span {
			color: $white;
			font-weight: bold;
		}
	}

	&.eux-office {
		background-color: $eux-office;
		span {
			color: $white;
			font-weight: bold;
		}
	}

	&.eux-food {
		background-color: $eux-food;
		span {
			color: $white;
			font-weight: bold;
		}
	}

	&.hf {
		background-color: $hf;
		span {
			color: $white;
			font-weight: bold;
		}
	}
}