@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.SortDndDragLayer {
	.SortDndDragLayer-item {
		border-radius: 0.25em;
		color: $white;
		background-color: $blue;
		padding: 0.5em 2.25em 0.5em 1em;
		margin-bottom: 0.75em;
		margin-right: 0.45em;
		line-height: 1em;
		white-space: nowrap;
		background-image: url('../../../assets/images/icons/icon-move.svg');
		background-position: center right 0.5em;
		background-size: auto 1em;		
		transform-origin: right 0.5em center;
		@include rotate(-7deg);
		@include box-shadow(0, 0.3em, 0, 0, $blueDark);
		&::before {
			content: '';
			background-image: linear-gradient(to bottom, rgba($white, 0.35), rgba($white, 0.1));
			height: 50%;
			width: 100%;
			border-top-left-radius: 0.25em;
			border-top-right-radius: 0.25em;
			position: absolute;
			top: 0;
			left: 0;
		}
		span {
			font-size: 0.88em;
		}		
	}
}