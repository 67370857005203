@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Login {
	position: absolute;
	width: 100%;
	height: 100%;

	.Login-wrapper {
		position: absolute;
		top: 18.1em;
		left: 37em;
		height: 20.3em;
		width: 18em;
		@include flex('space-between', '', 'column');
		@include transition('all', 0.5);

		.Login-form {
			.Login-fieldWrapper {
				position: relative;
				@include flex('','','column');
				
				.Login-fieldTitle {
					@include no-select();
					margin-top: 1.25em;
					span {
						font-size: 1.25em;
						color: $blueDark;
						font-weight: bold;
					}
				}

				.Login-classInput,
				.Login-input {
					height: 2.5em;
					padding-left: 0.5em;
					border-radius: 0.25em;
					border-style: solid;
					border-color: $green;
					border-width: 0.15em;
					color: $blueDark;
				}

				.Login-errorMessage {
					position: relative;
					margin-top: 0.5em;
					padding-left: 0.5em;
					span {
						color: $red;
						font-size: 0.75em;
					}
				}
			}
			.Login-button {
				@include flex('flex-end');
				margin-top: 1em;
			}
		}
	}

	.Login-facilitatorBtn {
		position: fixed;
		right: 1em;
		bottom: 1em;
		width: 8em;
		.Button {
			font-weight: 600;
		}
	}
}