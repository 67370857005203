@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.OrderDndContainer {
	position: relative;
	width: 100%;
	height: 100%;
	@include no-select();
	&.hover {
		border-radius: 0.25em;
		background-color: rgba($blueDark, 0.3);
	}
}