@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.MultipleChoice {
	position: relative;
	height: 100%;
	overflow: visible;
	@include hide-scrollbar();
		
	.MultipleChoice-intro {
		position: relative;
		width: 100%;
		margin-bottom: 1em;
	}

	.MultipleChoice-options {
		overflow: visible;
		position: relative;
		width: 36.33em;
		@include hide-scrollbar();

		.MultipleChoice-optionsWrap {
			position: relative;
			@include flex('space-between', 'flex-start');
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			
			.MultipleChoice-option {
				position: relative;
				line-height: 1.2;
				cursor: pointer;
				@include no-select();
				@include transition('transform', 0.1);
				span {
					color: $white;
					font-weight: bold;
				}
				&:hover {
					@include scale(1.1);
				}
				&.disabled {cursor: not-allowed;}
				.MultipleChoice-choiceIndicator {
					position: absolute;
					top: calc(50% - 1em);
					right: 0.5em;
					width: 2em;
					height: 2em;
					border-radius: 100%;
					border-color: rgba($black, 50%);
					border-top-width: 0.15em;
					border-top-style: solid;
					background-color: $blueDark;
					@include opacity(0.5);


					&.selected {
						@include opacity(1);
						border-top-style: none;

						&:after {
							content: '';
							position: absolute;
							display: block;
							width: 1.62em;
							height: 1.5em;
							top: 0;
							right: 0;
							background-image: url(../../../assets/images/icons/icon-check.svg);
							background-size: contain;
							background-origin: padding-box;
							background-repeat: no-repeat;
						}
					}
				}
			}
		}	
	}

	&:not(.images) {
		.MultipleChoice-options .MultipleChoice-optionsWrap {
			.MultipleChoice-option {
				@include flex('flex-start', 'center');
				width: 17em;
				min-height: calc(3.4em - 0.3em);
				border-radius: 0.25em;
				padding: 0.5em 3em 0.2em 1em;
				margin-bottom: 1.25em;
				background-color: $blue;
				@include box-shadow(0, 0.3em, 0, 0, $blueDark);
				&::before {
					content: '';
					background-image: linear-gradient(to bottom, rgba($white, 0.35), rgba($white, 0.1));
					height: 50%;
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
				}
				&.disabled {
					cursor: not-allowed;
				}
			}
		}
	}

	&.images {
		overflow: visible;

		.MultipleChoice-options {
			position: relative;
			overflow: visible;
			padding: 0;

			.MultipleChoice-optionsWrap {
				position: relative;
				@include flex('center', 'flex-start');

				.MultipleChoice-option { 
					width: 16.5em;
					height: 11em;
					min-height: auto;
					background: none;
					background-size: contain;
					background-position: center center;
					background-repeat: no-repeat;
					margin: 0;
					padding: 0;
					border-radius: 0;
					@include box-shadow(0, 0, 0, 0, transparent);
					@include transition('all', 0.2);
					border-style: solid;
					border-color: $blueDark;
					border-width: 0.1em;
					background-size: 101% 101%;

					&:hover {
						z-index: 2;
						@include scale(1.2);
						border-width: 0.5em;
					}

					&.selected {
						border-width: 0.5em;
					}

					span {
						display: none;
					}
					.MultipleChoice-choiceIndicator {
						position: absolute;
						top: 0.63em;
						right: 0.63em;
					}
				}
			}
		}
	}

	&.exciting-places {
		.MultipleChoice-options {
			padding-top: 0.5em;
			width: 52em;
			height: 20em;

			.MultipleChoice-optionsWrap {
				margin: 0 auto;
				width: 100%;

				.MultipleChoice-option {
					position: relative;

					&.position-1,
					&.position-2,
					&.position-4,
					&.position-5 {
						margin-right: 1em;
					}

					&.position-1,
					&.position-2,
					&.position-3 {
						margin-bottom: 1em;
					}

					&.option-1 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/exciting-places/option-1.png');
					}
					&.option-2 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/exciting-places/option-2.png');
					}
					&.option-3 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/exciting-places/option-3.png');
					}
					&.option-4 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/exciting-places/option-4.png');
					}
					&.option-5 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/exciting-places/option-5.png');
					}
					&.option-6 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/exciting-places/option-6.png');
					}
				}
			}
		}
	}

	&.challenge {
		.MultipleChoice-options {
			padding-top: 0.5em;
			width: 35em;
			height: 20em;

			.MultipleChoice-optionsWrap {
				margin: 0 auto;
				width: 100%;

				.MultipleChoice-option {
					position: relative;

					&.position-1,
					&.position-3 {
						margin-right: 1em;
					}

					&.position-1,
					&.position-2 {
						margin-bottom: 1em;
					}

					&.option-1 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/challenge/option-1.png');
					}
					&.option-2 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/challenge/option-2.png');
					}
					&.option-3 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/challenge/option-3.png');
					}
					&.option-4 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/challenge/option-4.png');
					}
				}
			}
		}
	}

	&.solving-tasks {
		.MultipleChoice-options {
			padding-top: 0.5em;
			width: 35em;
			height: 20em;

			.MultipleChoice-optionsWrap {
				margin: 0 auto;
				width: 100%;

				.MultipleChoice-option {
					position: relative;

					&.position-1,
					&.position-3 {
						margin-right: 1em;
					}

					&.position-1,
					&.position-2 {
						margin-bottom: 1em;
					}

					&.option-1 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/solving-tasks/option-1.png');
					}
					&.option-2 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/solving-tasks/option-2.png');
					}
					&.option-3 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/solving-tasks/option-3.png');
					}
					&.option-4 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/solving-tasks/option-4.png');
					}
				}
			}
		}
	}

	&.school {
		.MultipleChoice-options {
			padding-top: 0.5em;
			width: 35em;
			height: 20em;

			.MultipleChoice-optionsWrap {
				margin: 0 auto;
				width: 100%;

				.MultipleChoice-option {
					position: relative;

					&.position-1 {
						margin-right: 1em;
					}

					&.option-1 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/school/option-1.png');
					}
					&.option-2 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/school/option-2.png');
					}
				}
			}
		}
	}

	&.inspiration {
		.MultipleChoice-options {
			padding-top: 0.5em;
			width: 52em;
			height: 20em;

			.MultipleChoice-optionsWrap {
				margin: 0 auto;
				width: 100%;

				.MultipleChoice-option {
					position: relative;

					&.position-1,
					&.position-2,
					&.position-4,
					&.position-5 {
						margin-right: 1em;
					}

					&.position-1,
					&.position-2,
					&.position-3 {
						margin-bottom: 1em;
					}

					&.option-1 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/inspiration/option-1.png');
					}
					&.option-2 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/inspiration/option-2.png');
					}
					&.option-3 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/inspiration/option-3.png');
					}
					&.option-4 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/inspiration/option-4.png');
					}
					&.option-5 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/inspiration/option-5.png');
					}
					&.option-6 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/inspiration/option-6.png');
					}
				}
			}
		}
	}

	&.handywork {
		.MultipleChoice-options {
			padding-top: 0.5em;
			width: 52em;
			height: 20em;

			.MultipleChoice-optionsWrap {
				margin: 0 auto;
				width: 100%;

				.MultipleChoice-option {
					position: relative;

					&.position-1,
					&.position-2,
					&.position-4 {
						margin-right: 1em;
					}

					&.position-1,
					&.position-2,
					&.position-3 {
						margin-bottom: 1em;
					}

					&.option-1 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/handywork/option-1.png');
					}
					&.option-2 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/handywork/option-2.png');
					}
					&.option-3 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/handywork/option-3.png');
					}
					&.option-4 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/handywork/option-4.png');
					}
					&.option-5 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/handywork/option-5.png');
					}
				}
			}
		}
	}

	&.study {
		.MultipleChoice-options {
			padding-top: 0.5em;
			width: 52em;
			height: 20em;

			.MultipleChoice-optionsWrap {
				margin: 0 auto;
				width: 100%;

				.MultipleChoice-option {
					position: relative;

					&.position-1,
					&.position-2,
					&.position-4,
					&.position-5 {
						margin-right: 1em;
					}

					&.position-1,
					&.position-2,
					&.position-3 {
						margin-bottom: 1em;
					}

					&.option-1 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/study/option-1.png');
					}
					&.option-2 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/study/option-2.png');
					}
					&.option-3 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/study/option-3.png');
					}
					&.option-4 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/study/option-4.png');
					}
					&.option-5 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/study/option-5.png');
					}
					&.option-6 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/study/option-6.png');
					}
				}
			}
		}
	}

	&.interesting {
		.MultipleChoice-options {
			padding-top: 0.5em;
			width: 52em;
			height: 20em;

			.MultipleChoice-optionsWrap {
				margin: 0 auto;
				width: 100%;

				.MultipleChoice-option {
					position: relative;

					&.position-1,
					&.position-2,
					&.position-4,
					&.position-5 {
						margin-right: 1em;
					}

					&.position-1,
					&.position-2,
					&.position-3 {
						margin-bottom: 1em;
					}

					&.option-1 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/interesting/option-1.png');
					}
					&.option-2 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/interesting/option-2.png');
					}
					&.option-3 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/interesting/option-3.png');
					}
					&.option-4 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/interesting/option-4.png');
					}
					&.option-5 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/interesting/option-5.png');
					}
					&.option-6 {
						background-image: url('../../../assets/images/modules/tasks/multiple-choice/interesting/option-6.png');
					}
				}
			}
		}
	}

	.MultipleChoice-doneBtn {
		position: absolute;
		top: 34.15em;
		right: 10em;
	}

	.MultipleChoice-previousButton {
		position: absolute;
		top: 34.15em;
		left: -6.75em;
	}
}