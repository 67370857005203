@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.AdminLogin {
	height: 100%;
	width: 100%;
	@include flex('center', 'center', 'column');
	.AdminLogin-title {
		margin-bottom: 1em;
		text-align: center;
		span {
			font-size: 1.5em;
			color: $blueDark;
			font-weight: 800;
		}
	}

	.AdminLogin-container {
		width: 20em;
		height: 12.5em;
		padding: 1em 1.25em;
		border-radius: 0.6em;
		margin: 0 1em;
		text-align: left;
		color: $blueDark;
		background-color: white;

		.AdminLogin-form {
			position: relative;
			input {
				display: block;
				width: 100%;
				height: 2em;
				border: none;
				border-radius: 0.5em;
				padding: 0.5em 1em;
				margin: auto;
				margin-bottom: 1em;
				border: 1px solid $blueDark;
				color: $blueDark;
				font-weight: normal;
				&::placeholder {color: rgba($blueDark, 0.5);}
			}
			.Button {
				width: 100%;
				height: 2em;
				border-radius: 0.5em;
				margin-bottom: 1em;
				padding: 0;
				background-color: $blue;
				color: $white;
				font-weight: 500;
				text-transform: none;
				@include flex('center', 'center');
				&:hover {
					background-color: darken($blue, 3%);
				}
				&.loading {
					background-image: url('../../../assets/images/icons/icon-loading.svg');
					background-size: auto 80%;
					background-position: center right;
					background-repeat: no-repeat;
				}
			}
		}
	}
}

.AdminLogin-errorMessage {
	height: 1.25em;
	margin: 0.25em;

	text-align: left;
	span {
		font-size: 0.75em;
		color: $orange;
	}
}