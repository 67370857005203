@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.NextStep {
	position: absolute;
	width: 13em;
	height: 13em;

	&.active {
		width: 35.6em;
		height: 35.6em;
	}

	&.inactive {
		&.position-0 {
			top: 8em;
			left: 3.25em;
		}
		&.position-1 {
			top: 9.5em;
			left: 20.5em;
			@include scale(0.95);
		}
		&.position-2 {
			top: 21em;
			left: 11em;
			@include scale(0.85);
		}	
	}

	.NextStep-circle {
		position: relative;
		background-color: $white;
		border-radius: 100%;
		height: 100%;
		width: 100%;
		padding: 0.75em;
		@include transition('transform', 0.1);

		&.inactive {
		 	@include box-shadow(0em, 0.08em, 0.5em, 0.15em, rgba($black, 0.2));
		 	cursor: pointer;

			&:hover {
				@include scale(1.1);
			}
		}

		.NextStep-content {
			height: 100%;
			width: 100%;
			@include flex('center','center','column');
			.NextStep-title {
				@include no-select();
				position: relative;
				text-align: center;
				span {
					font-size: 1.7em;
					font-weight: bold;
					color: $blueDark;
				}
			}

			.NextStep-text {
				@include no-select();
				position: relative;
				text-align: center;
				line-height: 1.2em;
				span {
					font-size: 1em;
					color: $blueDark;
				}
			}

			.NextStep-moreBtn {
				position: absolute;
				bottom: 1.88em;
				width: 5em;
				height: 1.8em;
			}
		}

		.NextStep-openContent {
			position: absolute;
			top: 8.33em;
			left: 5em;
			width: 27em;
			line-height: 1.15em;

			.NextStep-title {
				@include no-select();
				position: relative;
				text-align: left;
				margin-bottom: 0.5em;
				span {
					font-size: 1.7em;
					font-weight: bold;
					color: $blueDark;
				}
			}

			.NextStep-text {
				position: relative;
				text-align: left;
				span {
					font-size: 1em;
					color: $blueDark;
				}
				margin-bottom: 0.5em;
			}

			.NextStep-description {
				font-size: 0.75em;
				color: $blueDark;

				a:visited {
					color: $blue;
				}
				a:hover {
					color: $blue;
				}
			}
		}

		.Result-closeBtn {
			position: absolute;
			width: 2.1em;
			height: 2.1em;
			top: 5em;
			right: 3.33em;
			background-image: url(../../../assets/images/icons/icon-close.svg);
			background-size: contain;
			cursor: pointer;
			@include transition('transform', 0.1);
			
			&:hover {
				@include scale(1.1);
			}
		}
	}
}