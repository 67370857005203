@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.CookieBanner {
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba($blueDark, 0.6);
	backdrop-filter: blur(1px);
	@include flex('center', 'flex-end');
	.CookieBanner-content {
		position: relative;
		width: 100%;
		background-color: $white;
		@include flex('space-between', 'center');
		padding-right: 5em;
		padding-left: 8.8em;
		padding-top: 1.88em;
		padding-bottom: 1em;

		.CookieBanner-cookieImage {
			width: 4.2em;
			height: 4.2em;
			background-image: url(../../../assets/images/icons/icon-cookie.svg)
		}
		.CookieBanner-cookieTextWrapper {
	
			.CookieBanner-cookieText {
				p {
					color: $blueDark;
					line-height: 1.4em;
					margin: 0;
				}
			}
			.CookieBanner-moreInfoLink {
				margin-top: 1em;
				cursor: pointer;
				span {
					color: $blueDark;
					line-height: 1;
					text-decoration: underline;
				}
			}
		}

		.CookieBanner-buttonWrapper {		
			.CookieBanner-button {
				width: 8.4em;
				&.no {
					margin-bottom: 0.9em;
				}
			}
		}
	}
}