@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Admin {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 3em 1em 1em 32em;
	font-size: calc(18 * ((100vw / 1920)));

	.Admin-backBtn {
		position: absolute;
		top: 12em;
		left: 1em;
		width: 12em;
	}

	.Admin-logout {
		position: absolute;
		top: 16em;
		left: 1em;
		width: 12em;
	}

	.Admin-navigation {
		margin-bottom: 2em;
		@include flex('flex-start', 'center');
		.Admin-navigationItem {
			position: relative;
			width: 10em;
			height: 3em;
			background-color: $white;
			border-radius: 2em;
			margin-right: 4em;
			cursor: pointer;
			@include opacity(0.5);
			@include flex('center', 'center');
			span {
				font-size: 1.5em;
			}
			&.selected {
				@include opacity(1);
			}
		}
	}
}

@media (min-aspect-ratio: 16/9) {
	.Admin {
		font-size: calc(18 * ((100vh / 1080)));
	}
}