@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.FacilitatorLogin {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: rgba($blueDark, 0.5);
	backdrop-filter: blur(1px);
	@include flex('center', 'center');

	.FacilitatorLogin-content {
		width: 25em;
		height: 28em;
		background-color: $white;
		border-radius: 0.5em;
		padding: 1em;

		.FacilitatorLogin-header {
			height: 4em;
			.FacilitatorLogin-title {
				text-align: center;
				span {
					font-size: 1.25em;
					font-weight: bold;
					color: $blueDark;
				}
			}
		}

		.FacilitatorLogin-body {	
			padding: 0 2em;	
			.FacilitatorLogin-form {
				.FacilitatorLogin-inputWrapper {
					width: 100%;
					margin-bottom: 1em;				
					span {
						display: inline-block;
						color: $blueDark;
						text-transform: capitalize;
					}
					
					.FacilitatorLogin-input {
						display: inline-block;
						width: 100%;
						height: 2em;
						font-size: 1em;
						font-weight: normal;
						padding-left: 0.5em;
						border-style: solid;
						color: $blueDark;
						border-radius: 0.25em;
					}
				}
				.FacilitatorLogin-loginButton {
					width: 10em;
					margin: 1em auto 0 auto;
				}
				.FacilitatorLogin-errorMessage {
					height: 1em;
					margin-top: 1em;
					span {
						font-size: 0.9em;
						color: red;
					}
				}
			}

			.FacilitatorPasswordReset-goToPageBtns {
				margin-top: 2em;
			}
		}
	}
}
