@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.OrderDndItem {
	position: relative;
	width: 16.91em;
	height: 3em;
	border-radius: 0.25em;
	background-color: $blueDark;
	cursor: move;
	@include transition('transform', 0.1);

	&.animate {
		@include flash(0.5s, 0s, 2, $blueDark, $blue);
	}
	
	&::before {
		content: '';
		background-image: linear-gradient(to bottom, rgba($white, 0.35), rgba($white, 0.1));
		height: 50%;
		width: 100%;
		position: absolute;
		border-top-left-radius: 0.25em;
		border-top-right-radius: 0.25em;
		top: 0;
		left: 0;
	}

	&:not(.isPreview) {
		&:hover {
			@include scale(1.05);
		}
	}

	.OrderDndItem-wrap {
		width: 100%;
		height: calc(3em - 0.28em);
		border-radius: 0.25em;
		background-color: $blue;
		padding: 0 2.5em 0 0.93em;
		line-height: 1.17;
		text-align: left;
		@include flex('flex-start', 'center');
		
		&.animate {
			@include flash(0.5s, 0s, 2);
		}

		&::after {
			content: '';
			position: absolute;
			top: -0.1em;
			right: 0;
			width: 2.65em;
			height: 100%;
			background-image: url('../../../assets/images/icons/icon-move.svg');
			background-size: 1.25em auto;
		}
		span {
			color: $white;
			font-weight: bold;
			padding-top: 0.2em;
		}
	}
	&.disabled {
		cursor: not-allowed;
	}	
}