@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.ResultsKeywords {
	position: relative;
	height: 100%;
	overflow: visible;
	
	.ResultsKeywords-title {
		margin-bottom: 1em;
		span {
			font-size: 1.67em;
			font-weight: 900;
			color: $blueDark;
		}
	}

	.ResultsKeywords-categories {
		position: relative;
		margin-top: 4em;
		width: 40em;
		@include flex('center');
		flex-wrap: wrap;
	}

	.ResultsKeywords-doneBtn {
		position: absolute;
		top: 34.15em;
		right: 10em;
	}

	.ResultsKeywords-backBtn {
		position: absolute;
		top: 34.15em;
		left: -7.5em;
	}
}