@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PopupResults {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($blueDark, 0.2);
	backdrop-filter: blur(1px);
	z-index: 15;
	@include flex('center', 'center');

	.PopupResults-content {
		position: relative;
		width: 80em;
		background-color: white;
		border-radius: 0.33em;
		border: solid 0.33em $blue;
		padding: 1em;
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba(black, 0.2));

		.PopupResults-closeBtn {
			cursor: pointer;
			position: absolute;
			right: 0.25em;
			top: 0.25em;
			width: 1.75em;
			height: 1.75em;
			background-image: url('../../../assets/images/icons/icon-close.svg');
		}

		.PopupResults-text {
			color: $blueDark;
			padding-bottom: 1em;
			.PopupResults-result {
				width: 100%;
				height: 2em;
				padding: 0.75em 0;
				margin-top: 1.5em;
				border-top: 1px dashed $blue;
				&:first-of-type {
					border-style: none;
				}
				.PopupResults-titleAndScore {
					height: 1em;
					line-height: 1;
					span {
						display: inline-block;
						font-size: 1em;
						white-space: nowrap;
						width: calc((100% - 22em) *  0.14);
						&:first-of-type {
							width: 25em;
						}
					}
				}
				.PopupResults-keywords {
					height: 1em;
					line-height: 1;
					span {
						display: inline-block;
						font-size: 0.6em;
						white-space: nowrap;
						width: 14.28%;
					}
				}
			}
		}
	}
}