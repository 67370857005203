@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.OrderDndPreview {	
	.OrderDndPreview-item {
		position: relative;
		width: 16.91em;
		height: 3em;
		border-radius: 0.25em;
		background-color: $blue;
		
		text-align: left;
		padding: 0 2.5em 0 0.93em;
		line-height: 1.17;
		@include flex('flex-start', 'center');
		@include box-shadow(0, 0.3em, 0, 0, $blueDark);
		span {
			color: white;
			font-weight: bold;
		}		
		&::after {
			content: '';
			position: absolute;
			height: 100%;
			width: 2.65em;
			right: 0;
			top: 0;
			cursor: move;
			background-image: url('../../../assets/images/icons/icon-move.svg');
			background-size: 1.25em auto;
		}
		
		&::before {
			content: '';
			background-image: linear-gradient(to bottom, rgba($white, 0.35), rgba($white, 0.1));
			height: 50%;
			width: 100%;
			position: absolute;
			border-top-left-radius: 0.25em;
			border-top-right-radius: 0.25em;
			top: 0;
			left: 0;
		}
	}
}