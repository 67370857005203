@import '../styles/fonts';
@import '../styles/variables';
@import '../styles/mixins';
@import '../styles/animations';
.App {
	position: relative;
	height: 100%;
	width: 100%;
	overflow: auto;
	background-color: $green;
	@include flex('flex-start', 'flex-start');

	.App-wrap {
		position: relative;
		width: 100%;
		height: 100%;

		.App-gameWrapper {
			position: relative;
			height: 100%;
			width: 100%;

			.App-logout {
				position: fixed;
				top: 1.25em;
				right: 1.25em;
				width: 5.6em;
				height: 1.67em;
			}
		}

		.App-toggleCookiePopup {
			position: absolute;
			left: 2em;
			bottom: 2em;
			width: 3em;
			height: 3em;
			background-image: url('../assets/images/icons/icon-cookie.svg');
			cursor: pointer;
			@include transition('transform', 0.1);

			&:hover {
				@include scale(1.1);
			}
		}

		.App-loginWrapper {
			position: relative;
		}

		.App-logoutPopup {
			position: fixed;
			top: 0;
			left: 0;
			background-color: rgba($blueDark, 0.5);
			backdrop-filter: blur(1px);
			width: 100%;
			height: 100%;
			@include flex('center', 'center');
			z-index: 99;

			.App-logoutPopupWindow {
				width: 25em;
				background-color: $white;
				border-radius: 0.5em;
				padding: 1em;

				.App-logoutPopupText {
					margin-bottom: 0.5em;
					line-height: 1.2em;
				}
				.App-logoutPopupButtons {
					@include flex('space-between');
	
					.App-logoutPopupButton {
						width: 10em;
					}
				}
			}
		}
	}
}

@media (min-aspect-ratio: 16/9) {
	.App .App-wrap {
		font-size: 2.22vh; // 24px at screen height ~1080px (2400/1080 ~ 2.22)
		width: 80em;
	}
}