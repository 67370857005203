$white: #ffffff;
$grey: #AFB0B2;
$black: #000000;
$textDark: #041F4F;

$green: #96B35C;

$blueLight: #CEDBF2;
$blueFlash: #75a4f5;
$blue: #3E72CC;
$blueDark: #203966;

$orange: #D95F37;
$red: #d93737;

$yellow: #D9B237;

// Next step colours
$stx: #A73B45;
$hhx: #2D659E;
$htx: #263457;
$eud-technology: #594171;
$eud-health: #594171;
$eud-office: #594171;
$eud-food: #594171;
$eux-technology: #85868A;
$eux-health: #85868A;
$eux-office: #85868A;
$eux-food: #85868A;
$hf: #9EB9D6;