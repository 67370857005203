@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Result {
	position: absolute;
	width: 14.3em;
	height: 14.3em;
	overflow: visible;
	
	&:not(.mac) {
		&.rank-1 {
			top: 6.5em;
			left: 3.25em;
		}
		&.rank-2 {
			top: 8em;
			left: 20.5em;
			@include scale(0.9);
		}
		&.rank-3 {
			top: 19.5em;
			left: 11em;
			@include scale(0.8);
		}
	}

	&.mac {
		&.rank-1 {
			top: 7.5em;
			left: 3.25em;
		}
		&.rank-2 {
			top: 9em;
			left: 20.5em;
			@include scale(0.9);
		}
		&.rank-3 {
			top: 20.5em;
			left: 11em;
			@include scale(0.8);
		}
	}

	&.small {
		@include scale(0.8);

		&:not(.mac) {
			&.rank-1 {
				top: 4.75em;
				left: 3.75em;
			}
			&.rank-2 {
				top: 9.5em;
				left: 20.5em;
			}
			&.rank-3 {
				top: 19.5em;
				left: 11em;
			}
		}

		&.mac {
			&.rank-1 {
				top: 5.75em;
				left: 3.75em;
			}
			&.rank-2 {
				top: 10.5em;
				left: 20.5em;
			}
			&.rank-3 {
				top: 20.5em;
				left: 11em;
			}
		}
	}

	.Result-circle {
		position: relative;
		cursor: pointer;
		width: 14.3em;
		height: 14.3em;
		border-radius: 100%;
		background-color: $white;
		@include flex('center',' center', column);
		@include transition('transform', 0.1);
		@include box-shadow(0em, 0.04em, 0.24em, 0.1em, rgba($black, 0.2));

		&:hover {
			@include scale(1.1);
		}

		.Result-numbering {
			@include no-select();
			position: absolute;
			height: 100%;
			@include flex('center',' center', column);

			span {
				font-size: 8em;
				color: rgba($blueDark, 0.25);
			}
		}
		.Result-title {
			@include no-select();
			text-align: center;
			padding: 0.5em;
			line-height: 1.7em;
			overflow-wrap: anywhere;
			span {
				font-weight: 900;
				font-size: 1.5em;
				color: $blueDark;
			}
		}
		.Result-examplesBtn {
			position: absolute;
			bottom: 2em;
			width: 7em;
			height: 1.8em;
		}
	}

	.Result-openCircle {
		position: relative;
		width: 35.6em;
		height: 35.6em;
		border-radius: 100%;
		background-color: $white;
		z-index: 2;
		
		.Result-openCircleContent {
			position: relative;
			top: 8.33em;
			left: 5em;
			width: 25.7em;
			@include flex('', '', 'column');
			.Result-openCircleTitle {
				position: relative;
				span {
					color: $blueDark;
					font-size: 1.67em;
					font-weight: 900;
				}
			}

			.Result-openCircleText {
				position: relative;
				margin-top: 0.5em;
				margin-bottom: 0.75em;
				line-height: 1em;
				span {
					font-size: 0.75em;
					color: $blueDark;
				}
			}

			.Result-openCircleSubText {
				position: relative;
				line-height: 1em;
				&.hasSpacing {
					margin-bottom: 1em;
				}
				span {
					font-size: 0.75em;
					color: $blueDark;
					&.Result-openCircleSubTitle {
						font-weight: bold;
						margin-right: 0.5em;
					}
				}
			}

			.Result-openCircleCareers {
				position: relative;
				@include flex('');
				flex-wrap: wrap;
				
				.Result-openCircleCareer {
					padding: 1.2em;
					padding-top: 0.3em;
					padding-bottom: 0.3em;
					background-color: $blueLight;
					border-radius: 1.25em;
					margin-right: 0.5em;
					margin-bottom: 0.5em;
					line-height: 1em;

					span {
						font-size: 0.7em;
						color: $blueDark;
					}
				}
			}
		}

		.Result-closeBtn {
			position: absolute;
			width: 2.1em;
			height: 2.1em;
			top: 5em;
			right: 3.33em;
			background-image: url(../../../assets/images/icons/icon-close.svg);
			background-size: contain;
			cursor: pointer;
			@include transition('transform', 0.1);
			
			&:hover {
				@include scale(1.1);
			}
		}
	}
}