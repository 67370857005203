@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.CookieWarning {
	position: relative;
	width: 100%;
	height: 100%;

	.CookieWarning-text {
		@include transition('all', 0.5);
		position: absolute;
		z-index: 2;
		top: 23em;
		left: 35.5em;

		.CookieWarning-welcomeText{
			position: relative;
			width: 20em;
			left: 1em;
			span {
				color: $blueDark;
				font-weight: 600;
				font-size: 1.25em;
			}
			&.topPadded {
				margin-top: 2em;
			}
			&.bold {
				span {
					font-weight: bold;
				}
			}
		}
		
		span {
			color: $blueDark;
			font-weight: bold;
		}

		.CookieWarning-button {
			position: absolute;
			top: 10em;
			left: 16.75em;
		}
	}
}